<template>
  <Dialog
    header="Filtrar"
    v-model:visible="dialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 60vw; text-align: center"
    :modal="true"
  >
    <div v-for="(row, i) in options.items" :key="i" class="mb-4">
      <template v-if="!row.condition || row.condition(filter)">
        <span
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            color: #585857;
          "
        >
          {{ row.title }}
        </span>
        <div class="my-2">
          <div class="cardFilter" style="padding: 34px">
            <div v-for="(opt, j) in row.options" :key="j" class="">
              <label
                :for="opt.value"
                style="display: flex; align-items: center"
                class="city filter-text"
              >
                <Image
                  v-if="opt.image"
                  :src="opt.image"
                  alt="Image"
                  width="50"
                  style="padding-right: 5px"
                />
                <RadioButton
                  :inputId="opt.value"
                  :name="row.value"
                  :value="opt.value"
                  v-model="filter[`${row.value}`]"
                  class="mr-2"
                />
                <p class="filter-text m-0">{{ opt.label }}</p>
              </label>
            </div>
          </div>
        </div>
      </template>
    </div>
    <Button label="Atualizar" @click="save" />
  </Dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    filter: {},
  }),
  methods: {
    open() {
      this.dialog = true;
    },
    save() {
      this.$emit("change", this.filter);
      this.dialog = false;
    },
    initFilters() {
      this.options.items.forEach((item) => {
        this.filter[item.value] = item.options.find((v) => v.checked)?.value;
      });
    },
  },
  mounted() {
    this.initFilters();
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    options: [Object, Array],
  },
};
</script>

<style>
.cardFilter {
  padding-top: 20px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 14px;
  padding-top: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 14px;
  display: flex;
  justify-content: space-around !important;
}
</style>
